import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/layout"
import { motion } from "framer-motion"

const TagsPage = ({ data: { allMdx: { group } } }) => {
    return (<Layout pageTitle="tags" description="All tags of all the articles. Click on one to find the articles with that tag.">
        <ul>
            {group.map(({ fieldValue: tag }) => <motion.li layoutId={`tag${tag}`} key={`${tag}`}>
                <Link to={`/tags/${tag}`}>{tag}</Link>
            </motion.li>)}
        </ul>
    </Layout>)
}

export default TagsPage

export const query = graphql`
query {
  allMdx(filter: {frontmatter: {draft: {ne: true}}}) {
    group(field: frontmatter___tags) {
      fieldValue
    }
  }
}
`
